* {
  box-sizing: border-box;
  /* margin: 0; */
  /* padding: 0; */
  font-family: 'sans-serif';
  background-color: black;
  color: #fff;
}

.home,
.site,
.profile {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.site,
.profile {
  /* background-image: url('/public/images/img-2.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px; */
  display:block;
  /* font-size: 1.5em; */
  font-size: 1.5rem;
}


.products {
  background-image: url('/public/images/img-1.jpg');
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}
